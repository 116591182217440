import classNames from 'classnames';
import { Spacing, gapProperties } from "./sizing";

export type RowsProps = {
  gap: Spacing;
  children: React.ReactNode;
}

export const Rows = (props: RowsProps) => {
  const { children, gap } = props;
  return (
    <div className={classNames("flex flex-col", {
      ...gapProperties('gap-y', gap),
    })}>
      <>{children}</>
    </div >
  )
}
