import { Button } from "ui/atoms/button/button"
import { Container } from "ui/atoms/container/container"
import { Divider } from "ui/atoms/divider/divider"
import { Grid } from "ui/atoms/grid/grid"
import { Rows } from "ui/atoms/layout/rows"
import { Spacer } from "ui/atoms/layout/spacer"
import { PortfolioBalance } from "ui/molecules/portfolio-balance/portfolio-balance"

export const Dashboard = () => {
  return (
    <Container p="4">
      <Rows gap="2">
        <PortfolioBalance value={690000} />
        <Divider />
        <Spacer height="2" />
        <Grid columns="4" gap="1">
          <Button>Buy</Button>
          <Button>Send</Button>
          <Button>Receive</Button>
          <Button>Add</Button>
        </Grid>
        <Spacer height="2" />
        <Grid columns="4" gap="2">
          <Button>test</Button>
          <Button>test</Button>
          <Button>test</Button>
          <Button>test</Button>
          <Button>test</Button>
          <Button>test</Button>
          <Button>test</Button>
          <Button>test</Button>
        </Grid>
      </Rows>
    </Container>
  )
}