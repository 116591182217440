import classNames from "classnames";
import { Spacing } from "./sizing"

type SpacerProps = {
  height?: Spacing;
  width?: Spacing;
}

export const Spacer = ({ height, width }: SpacerProps) => {
  return (
    <div className={classNames({
      'h-0': height === '0',
      'h-0.5': height === '0.5',
      'h-1': height === '1',
      'h-2': height === '2',
      'h-3': height === '3',
      'h-4': height === '4',
      'h-5': height === '5',
      'w-0': width === '0',
      'w-0.5': width === '0.5',
      'w-1': width === '1',
      'w-2': width === '2',
      'w-3': width === '3',
      'w-4': width === '4',
      'w-5': width === '5',
    })} />
  )
}