import classNames from "classnames";
import { Padding, paddingProperties } from "ui/atoms/layout/sizing";

export type BoxProps = Padding & {
  children: React.ReactNode;
}

export const Box = (props: BoxProps) => {
  const { children, ...padding } = props;
  return (
    <div className={classNames("flex", {
      ...paddingProperties(padding),
    }
    )}>
      <>{children}</>
    </div >
  )
}