import classNames from "classnames";
import { Padding, paddingProperties } from "ui/atoms/layout/sizing";

export type ContainerProps = Padding & {
  children: React.ReactNode;
}

export const Container = ({ children, ...padding }: ContainerProps) => {
  return (
    <div className={classNames({
      ...paddingProperties(padding)
    })}>
      {children}
    </div>
  )
}