import classNames from "classnames";

type TextColor = 'current' | 'normal' | 'light' | 'lighter' | 'lightest' | 'dark' | 'darker' | 'darkest' | 'black' | 'white' | 'grey';

type FontSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

type FontWeight = 'light' | 'normal' | 'bold';

export type TextProps = {
  tagName?: 'div' | 'span';
  color?: TextColor;
  size?: FontSize;
  weight?: FontWeight;
  children: string;
}

export const Text = (props: TextProps) => {
  const { tagName: Tag = 'div', color = 'normal', size = 'base', weight = 'normal', children } = props;
  const classnames = classNames("font-sans", {
    'text-xs': size === 'xs',
    'text-sm': size === 'sm',
    'text-base': size === 'base',
    'text-lg': size === 'lg',
    'text-xl': size === 'xl',
    'font-extralight': weight === 'light',
    'font-normal': weight === 'normal',
    'font-semibold': weight === 'bold',
    'text-slate-300': color === 'lightest',
    'text-slate-400': color === 'lighter',
    'text-slate-500': color === 'light',
    'text-slate-600': color === 'normal',
    'text-slate-700': color === 'dark',
    'text-slate-800': color === 'darker',
    'text-slate-900': color === 'darkest',
  })
  return (
    <Tag className={classnames}>
      {children}
    </Tag>
  )
}