export type Spacing = '0' | '0.5' | '1' | '2' | '3' | '4' | '5';

export type Padding = {
  p?: Spacing,
  pl?: Spacing,
  pr?: Spacing,
  pt?: Spacing,
  pb?: Spacing,
  px?: Spacing,
  py?: Spacing,
}

export const gapProperties = (type: "gap" | "gap-x" | "gap-y", value?: string) => {
  switch (type) {
    case "gap":
      return {
        'gap-0': value === '0',
        'gap-0.5': value === '0.5',
        'gap-1': value === '1',
        'gap-2': value === '2',
        'gap-3': value === '3',
        'gap-4': value === '4',
        'gap-5': value === '5',
      }
    case "gap-x":
      return {
        'gap-x-0': value === '0',
        'gap-x-0.5': value === '0.5',
        'gap-x-1': value === '1',
        'gap-x-2': value === '2',
        'gap-x-3': value === '3',
        'gap-x-4': value === '4',
        'gap-x-5': value === '5',
      }
    case "gap-y":
      return {
        'gap-y-0': value === '0',
        'gap-y-0.5': value === '0.5',
        'gap-y-1': value === '1',
        'gap-y-2': value === '2',
        'gap-y-3': value === '3',
        'gap-y-4': value === '4',
        'gap-y-5': value === '5',
      }
  }
}

export const paddingProperties = (props: Padding) => {
  return {
    'p-0': props.p === '0',
    'p-0.5': props.p === '0.5',
    'p-1': props.p === '1',
    'p-2': props.p === '2',
    'p-3': props.p === '3',
    'p-4': props.p === '4',
    'p-5': props.p === '5',
    'pl-0': props.pl === '0',
    'pl-0.5': props.pl === '0.5',
    'pl-1': props.pl === '1',
    'pl-2': props.pl === '2',
    'pl-3': props.pl === '3',
    'pl-4': props.pl === '4',
    'pl-5': props.pl === '5',
    'pr-0': props.pr === '0',
    'pr-0.5': props.pr === '0.5',
    'pr-1': props.pr === '1',
    'pr-2': props.pr === '2',
    'pr-3': props.pr === '3',
    'pr-4': props.pr === '4',
    'pr-5': props.pr === '5',
    'pt-0': props.pt === '0',
    'pt-0.5': props.pt === '0.5',
    'pt-1': props.pt === '1',
    'pt-2': props.pt === '2',
    'pt-3': props.pt === '3',
    'pt-4': props.pt === '4',
    'pt-5': props.pt === '5',
    'pb-0': props.pb === '0',
    'pb-0.5': props.pb === '0.5',
    'pb-1': props.pb === '1',
    'pb-2': props.pb === '2',
    'pb-3': props.pb === '3',
    'pb-4': props.pb === '4',
    'pb-5': props.pb === '5',
    'px-0': props.px === '0',
    'px-0.5': props.px === '0.5',
    'px-1': props.px === '1',
    'px-2': props.px === '2',
    'px-3': props.px === '3',
    'px-4': props.px === '4',
    'px-5': props.px === '5',
    'py-0': props.py === '0',
    'py-0.5': props.py === '0.5',
    'py-1': props.py === '1',
    'py-2': props.py === '2',
    'py-3': props.py === '3',
    'py-4': props.py === '4',
    'py-5': props.py === '5',
  }
}