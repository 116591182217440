import { Box } from "ui/atoms/layout/box"
import { Rows } from "ui/atoms/layout/rows";
import { Text } from "ui/atoms/text/text"

type PortfolioBalanceProps = {
  value: number;
}

export const PortfolioBalance = ({ value }: PortfolioBalanceProps) => {
  return (
    <Box p="2">
      <Rows gap="0.5">
        <Text color="light" weight="bold">My balance</Text>
        <Text color="darkest" size="xl" weight="bold">
          {new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value)}
        </Text>
      </Rows>
    </Box>
  )
}